
import { defineComponent, ref, Ref } from 'vue';
import { office, accountType } from '@/data';
import Notice from '@/util/notice.base';
import userDialog from '@/components/view/super/user-info-dialog';
import billingInformation from '@/components/view/common/dialog/billing-information';
import { list } from '@/components/common/list';
import { userForSuperApi } from '@/api';
import { getOfficeInfoDevice } from './util';

const infoKey: Array <{
    label: string;
    key: keyof accountType.OfficeUserInfo;
}> = [{
    key: 'Name',
    label: WordList.TabelPersonUserInHtmlName
}, {
    key: 'SipAccount',
    label: WordList.ProperAllTextSIPNumber
}, {
    key: 'Email',
    label: WordList.TabelPropertyManageEmail
}, {
    key: 'MobileNumber',
    label: WordList.ProperAllTextMobileNumber
}, {
    key: 'Install',
    label: WordList.ProperAllTextInstaller
}, {
    key: 'Phone',
    label: WordList.TabelPersonUserInHtmlPhone
}, {
    key: 'CreateTime',
    label: WordList.TabelMessageBoxCreateTime
}, {
    key: 'ExpireTime',
    label: WordList.TabelExpretimeBoxSpecificTime
}];
export default defineComponent({
    props: {
        id: {
            type: String,
            required: true
        }
    },
    components: {
        billingInformation,
        userDialog,
        list
    },
    setup(props) {
        const {
            deviceHeaders,
            devRequestData
        } = getOfficeInfoDevice(props.id);
        // 请求办公用户详情，参数用户ID
        const officeInfo: Ref< accountType.OfficeUserInfo > = ref({
            Name: '',
            SipAccount: '',
            Email: '',
            MobileNumber: '',
            Install: '',
            Phone: '',
            CreateTime: '',
            ExpireTime: '',
            Active: '0'
        });
        const getOfficeInfo = () => {
            office.getOfficeUserInfo(props.id, (res: {
                data: accountType.OfficeUserInfo;
            }) => {
                officeInfo.value = res.data;
            });
        };
        getOfficeInfo();

        // 获取办公成员支付账单信息
        const isShowBillInfo = ref(false);
        const getBillingInfo = () => {
            isShowBillInfo.value = true;
        };

        // 激活用户
        const activeUser = () => {
            Notice.messageBox('confirm', WordList.ProperAllTextActiveAccountTips, '', 'info')(() => {
                userForSuperApi.activeAccount({
                    ID: props.id
                }, () => {
                    getOfficeInfo();
                });
            });
        };

        // 设置过期时间
        const isShowCnfDialog = ref(false);
        return {
            officeInfo,
            infoKey,
            getBillingInfo,
            activeUser,
            getOfficeInfo,
            isShowBillInfo,
            isShowCnfDialog,
            deviceHeaders,
            devRequestData
        };
    }
});
