
import { userForSuperApi } from '@/api';
import dialogShell from '@/components/common/dialog-shell/index.vue';
import { defineComponent, reactive } from 'vue';
import { BillingForm } from './all.type';

interface ActivityData {
    ActivityName: string;
    ActivityNumber: string;
}
export default defineComponent({
    components: {
        dialogShell
    },
    props: {
        userId: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const billingInfo: BillingForm = reactive({
            BillingModel: '',
            BillingInstance: '',
            Fee: '',
            MarketingActivities: ''
        });

        userForSuperApi.getUserBilling({ EnduserID: props.userId }, (res: any) => {
            if (res[0].BillingModelData.length > 0) {
                billingInfo.BillingModel = `${res[0].BillingModelData[0].BillingModelName}
                (${WordList.Number}: ${`${res[0].BillingModelData[0].BillingModelNumber})`}`;
            }
            if (res[0].InstanceData.length > 0) {
                billingInfo.BillingInstance = `${res[0].InstanceData[0].InstanceName}
                (${WordList.Number}: ${`${res[0].InstanceData[0].InstanceNumber})`}`;
            }
            billingInfo.Fee = `${res[0].ActiveFee},${res[0].MonthlyFee},${res[0].AppNumber},${res[0].AddAppFee}`;
            billingInfo.MarketingActivities = '';
            res[0].ActivityValidPeriod.forEach((item: ActivityData) => {
                billingInfo.MarketingActivities = `${billingInfo.MarketingActivities}${item.ActivityName}
                (${WordList.Number}: ${`${item.ActivityNumber})`}\n `;
            });
        });

        return {
            billingInfo
        };
    }
});
