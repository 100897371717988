import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "info-item-bottom" }
const _hoisted_2 = { class: "info-item-bottom font-weight600" }
const _hoisted_3 = { class: "info-item-bottom" }
const _hoisted_4 = { class: "info-item-bottom font-weight600" }
const _hoisted_5 = { class: "info-item-bottom" }
const _hoisted_6 = { class: "info-item-bottom font-weight600" }
const _hoisted_7 = { class: "info-item-bottom" }
const _hoisted_8 = { class: "info-item-bottom font-weight600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dialog_shell = _resolveComponent("dialog-shell")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_dialog_shell, {
      footerType: "customize",
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
      title: _ctx.$wordList.OrderBillingInformation
    }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_1, [
          _createVNode("p", _hoisted_2, _toDisplayString(_ctx.$wordList.BillingModel), 1),
          _createVNode("p", null, _toDisplayString(_ctx.billingInfo.BillingModel), 1)
        ]),
        _createVNode("div", _hoisted_3, [
          _createVNode("p", _hoisted_4, _toDisplayString(_ctx.$wordList.BillingInstance), 1),
          _createVNode("p", null, _toDisplayString(_ctx.billingInfo.BillingInstance), 1)
        ]),
        _createVNode("div", _hoisted_5, [
          _createVNode("p", _hoisted_6, _toDisplayString(_ctx.$wordList.Fee), 1),
          _createVNode("p", null, _toDisplayString(_ctx.billingInfo.Fee), 1)
        ]),
        _createVNode("div", _hoisted_7, [
          _createVNode("p", _hoisted_8, _toDisplayString(_ctx.$wordList.MarketingActivities), 1),
          _createVNode("p", null, _toDisplayString(_ctx.billingInfo.MarketingActivities), 1)
        ])
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}