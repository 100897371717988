import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sec_date_picker = _resolveComponent("sec-date-picker")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_form = _resolveComponent("el-form")
  const _component_dialog_shell = _resolveComponent("dialog-shell")

  return (_openBlock(), _createBlock(_component_dialog_shell, {
    title: _ctx.$wordList.TabelExpretimeBoxExpreTime,
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close'))),
    onSubmit: _ctx.submit
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        class: "width70per margin-0-auto",
        ref: "formRef",
        model: _ctx.formData,
        rules: _ctx.fromRules,
        "label-position": _ctx.$formLabelPosition
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: _ctx.$wordList.TabelExpretimeBoxExpreTime,
            prop: "ValidTime"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_sec_date_picker, {
                type: "datetime",
                modelValue: _ctx.formData.ValidTime,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.formData.ValidTime = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["model", "rules", "label-position"])
    ]),
    _: 1
  }, 8, ["title", "onSubmit"]))
}