
import { defineComponent, Ref, ref } from 'vue';
import { format } from '@/methods/date';
import HttpRequest from '@/util/axios.config';

export default defineComponent({
    props: {
        expireTime: {
            required: true,
            type: String
        },
        id: {
            required: true,
            type: String
        }
    },
    setup(props, { emit }) {
        const formRef: Ref<any> = ref(null);
        const fromRules = {
            ValidTime: [{ type: 'date', required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelExpretimeBoxExpreTime) }]
        };

        const formData = ref({
            ValidTime: props.expireTime === '--' ? '' : new Date(props.expireTime)
        });

        const submit = () => {
            formRef.value.validate((valid: boolean) => {
                if (valid) {
                    const formatTime = format(formData.value.ValidTime);
                    HttpRequest.post('setusercnf', {
                        ID: props.id,
                        ValidTime: formatTime
                    }, () => {
                        emit('success');
                        emit('close');
                    });
                }
            });
        };

        return {
            formRef,
            fromRules,
            formData,
            submit
        };
    }
});
